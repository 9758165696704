import React, { useEffect, useState } from "react"
import Layout from "../../components/Community/Layout"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { client } from "../../sanity"
import { Flex, Title, Label, Grid } from "../../components/system"
import Stage from "../../components/Community/stage"
import Loading from "../../components/Loading"

const message = (stage, name) => {
  switch (stage) {
    case 1:
      return `Hello, ${name} here 👋. I will be your fashion consultant`
      break
    case 2:
      return "Hey there … Congratulations on completing Stage 1. Let’s get cutting and making."
      break
    case 3:
      return "Hey there … You are doing amazing. Here comes Stage 3. Keep up the good work."
      break
    case 4:
      return "Hey …  you’re nearly there, your last and final stage. This is where the magic happens 😃"
      break
    default:
      return "If you require any further support please drop us an email at community@ilana.uk"
  }
}

const Process = () => {
  const { user } = useAuth0()
  const [stages, setStages] = useState()
  const [consultant, setConsultant] = useState()

  useEffect(() => {
    const query =
      '*[_type == "stage" && owner._ref in *[_type=="user" && uuid==$uuid]._id ]| order(_createdAt asc){_id,stage, owner->{name,assigned->{name}}, images,files, approved}'
    const params = { uuid: `${user.sub}` }

    client.fetch(query, params).then(data => {
      const approved = data.filter(thing => thing.approved === true)
      const stageNumber = {
        current: approved.length + 1,
        one: {
          submitted: data[0] !== undefined ? true : false,
          approved: data[0]?.approved,
          id: data[0]?._id,
        },
        two: {
          submitted: data[1] !== undefined ? true : false,
          approved: data[1]?.approved,
          id: data[1]?._id,
        },
        three: {
          submitted: data[2] !== undefined ? true : false,
          approved: data[2]?.approved,
          id: data[2]?._id,
        },
        four: {
          submitted: data[3] !== undefined ? true : false,
          approved: data[3]?.approved,
          id: data[3]?._id,
        },
      }
      setStages(stageNumber)
    })

    const expert = '*[_type == "user" && uuid == $uuid]{assigned->{name}}'

    client.fetch(expert, params).then(data => {
      setConsultant(data[0].assigned.name)
    })
  }, [user])

  if (!stages) {
    return <Loading />
  }

  return (
    <Layout>
      <Flex flexDirection="column" mb={[4, 7]}>
        <Flex flexDirection="column" py={5} px={[4, 7]}>
          <Label color="faded">{`Stage ${stages?.current}`}</Label>
          <Title fontSize={[5, 6]} mt={2}>
            {message(stages?.current, consultant)}
          </Title>
        </Flex>
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
          gridGap={4}
          my={5}
        >
          <Link
            to={
              stages?.one.submitted
                ? `/community/view-stage/${stages?.one.id}`
                : "/community/stage-one"
            }
          >
            <Stage
              number="1"
              title="Fashion Sketch & CAD Design"
              visible={true}
              submitted={stages?.one.submitted}
              approved={stages?.one.approved}
            />
          </Link>
          <Link
            to={
              stages?.one.approved && !stages?.two.submitted
                ? "/community/stage-two"
                : stages?.two.submitted
                ? `/community/view-stage/${stages?.two.id}`
                : "/community/process"
            }
          >
            <Stage
              number="2"
              title="Toile & Pattern"
              visible={stages?.one.approved}
              submitted={stages?.two.submitted}
              approved={stages?.two.approved}
            />
          </Link>
          <Link
            to={
              stages?.two.approved && !stages?.three.submitted
                ? "/community/stage-three"
                : stages?.three.submitted
                ? `/community/view-stage/${stages?.three.id}`
                : "/community/process"
            }
          >
            <Stage
              number="3"
              title="Sample"
              visible={stages?.two.approved}
              submitted={stages?.three.submitted}
              approved={stages?.three.approved}
            />
          </Link>
          <Link
            to={
              stages?.three.approved && !stages?.four.submitted
                ? "/community/stage-four"
                : stages?.four.submitted
                ? `/community/view-stage/${stages?.four.id}`
                : "/community/process"
            }
          >
            <Stage
              number="4"
              title="Collection"
              visible={stages?.three.approved}
              submitted={stages?.four.submitted}
              approved={stages?.four.approved}
            />
          </Link>
        </Grid>
      </Flex>
    </Layout>
  )
}

export default Process
